import React from "react";
import Layout from "../components/Layout";
import Product from "../components/Products/Product";
import { chickenProducts } from "../data/chicken/chickenProduct";

const Chicken = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  return (
    <Layout title={chickenProducts[i].recipeName} description="">
      <Product id={i} proteinProducts={chickenProducts} />
    </Layout>
  );
};

export default Chicken;
